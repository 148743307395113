type UpsellOpenRequestedEvent = Event;
type UpsellCloseRequestedEvent = Event;

declare global {
  interface WindowEventMap {
    'upsell-close-requested': UpsellCloseRequestedEvent;
    'upsell-open-requested': UpsellOpenRequestedEvent;
  }
}

function onUpsellOpenRequested(_event: UpsellOpenRequestedEvent) {
  const upsellModal = document.querySelector('upsell-modal');
  if (upsellModal) {
    const cartDrawer = document.querySelector('cart-drawer');
    if (cartDrawer) {
      upsellModal.dataset.animate = 'false';
    } else {
      // only animate the upsell modal when the cart drawer is not available
      // setting this again on close() is not needed
      upsellModal.dataset.animate = 'true';
    }

    upsellModal.dataset.show = 'true';
  }

  addEventListener('shroud-clicked', onUpsellCloseRequested);
}

function onUpsellCloseRequested(_event: UpsellCloseRequestedEvent) {
  removeEventListener('shroud-clicked', onUpsellCloseRequested);

  const upsellModal = document.querySelector('upsell-modal');
  if (upsellModal) {
    upsellModal.dataset.show = 'false';
  }
}

addEventListener('upsell-open-requested', onUpsellOpenRequested);
addEventListener('upsell-close-requested', onUpsellCloseRequested);
